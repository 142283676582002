// import { isMobile, isTablet, isDesktop } from "../functions/check-viewport.js";
document.addEventListener("DOMContentLoaded", function () {
  const limit = 3;
  const nextOpen = (wrapper, button) => {
    const boxs = wrapper.querySelectorAll(".assortment-item"),
      len = boxs.length - 2,
      endBox = wrapper.querySelector(".nextstop"),
      index = [...boxs].indexOf(endBox) + limit;
    if (endBox) endBox.classList.remove("nextstop");
    if (index < len) boxs[index].classList.add("nextstop");
    else button.remove();
  };

  if (document.documentElement.clientWidth <= 1024) {
    console.log("mobile");

    document
      .querySelectorAll(".section-assortment__grid")
      .forEach((wrapper) => {
        const button = wrapper.nextElementSibling;
        button.addEventListener("click", nextOpen.bind(null, wrapper, button));
        nextOpen(wrapper, button);
      });
  }
});
